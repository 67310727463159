<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="housing_building_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('housing_building')">
                            <HouseBuildingSelectbox
                                v-model="formData.housing_building_id"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('type')">
                            <parameterSelectbox
                                :code="'housing_room_types'"
                                v-model="formData.type"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('room_name')">
                            <b-form-input v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="fee" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('fee')">
                            <b-form-input v-model="formData.fee">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="number_of_beds" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('number_of_beds')">
                            <b-form-input v-model="formData.number_of_beds">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="floor" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('floor')">
                            <b-form-input v-model="formData.floor">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="tenant_type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('tenant_type')">
                            <parameter-selectbox
                                :multiple="true"
                                :code="'housing_tenant_types'"
                                :validate-error="errors[0]"
                                v-model="formData.tenant_types"
                            ></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="gender" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox
                                :validate-error="errors[0]"
                                v-model="formData.gender"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                :valueType="'chr'"
                                v-model="formData.status"
                            />
                        </b-form-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" >
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="" v-slot="{valid, errors}">
                            <b-input-group class="mb-1">
                                <b-form-textarea
                                    v-model="formData.explanation"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

            </b-row>



            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingRoom from "@/services/HousingRoomServices";
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";

export default {
    components: {
        GenderSelectbox,
        ValidationProvider,
        ValidationObserver,
        HouseBuildingSelectbox
    },
    data() {
        return {
            formData: {
                name: null,
                address: null,
                tenant_types: [],
            },
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                HousingRoom.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
